import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";
import { BrowserRouter } from "react-router-dom";

import "@sellernote/_shared/src/i18n/initI18nForCRA";

import { USES_PRODUCTION_TRACKING } from "@sellernote/_shared/src/constants";
import { SnackbarProvider } from "@sellernote/_shared/src/hooks/admin/useSnackbar/SnackbarProvider";
import { initSentry } from "@sellernote/_shared/src/services/sentry/sentryForCRA";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

initSentry();

// GTM초기화
if (USES_PRODUCTION_TRACKING && process.env.REACT_APP_GTM_ID) {
  TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID });
}

ReactDOM.render(
  <BrowserRouter>
    <SnackbarProvider>
      <App />
    </SnackbarProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
